import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import theme from '../../../../util/theme';
import { mobileSize } from '../../../../util/variables';
import { BlogH3, Overline } from '../../../ui';

interface Props {
  category: string;
  title: string;
  imgUrl: string;
  id: string;
  slug: string;
}

export const LargeOverlayArticleCard: React.FC<Props> = ({ id, category, title, imgUrl, slug }) => {
  return (
    <Link to={`/post/${slug}`}>
      <Container imgUrl={imgUrl}>
        <MaskDiv>
          <Overline white>{category}</Overline>
          <BlogH3 color={theme.WHITE_COLOR} style={{ margin: 0 }}>
            {title}
          </BlogH3>
        </MaskDiv>
      </Container>
    </Link>
  );
};

const Container = styled.div<{ imgUrl: string }>`
  position: relative;
  width: 560px;
  height: 560px;
  aspect-ratio: 1/1;
  color: ${theme.WHITE_COLOR};
  background-color: ${theme.GREY_800};
  background-image: ${props => (props.imgUrl ? `url(${props.imgUrl})` : theme.GREY_800)};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media ${mobileSize} {
    height: auto;
    width: 100%;
  }
`;

const MaskDiv = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  bottom: 0;
  width: 100%;
  padding: 32px;
  background-color: rgba(0, 0, 0, 0.3);
`;
