import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import theme from '../../../../util/theme';
import { BlogText, Overline } from '../../../ui';
import { FaArrowRight } from 'react-icons/fa';

interface Props {
  category: string;
  title: string;
  imgUrl?: string;
  id: string;
  slug: string;
}

export const OverlayArticleCard: React.FC<Props> = ({ id, category, title, imgUrl, slug }) => {
  return (
    <Link to={`/post/${slug}`}>
      <Container img={imgUrl}>
        <MaskDiv>
          <TextWrapper>
            <Overline white>{category}</Overline>
            <BlogText size="Large" color={theme.WHITE_COLOR} style={{ margin: 0 }}>
              {title}
            </BlogText>
            <IconContainer>
              <FaArrowRight color={theme.PITCH_BLACK} size={20} />
            </IconContainer>
          </TextWrapper>
        </MaskDiv>
      </Container>
    </Link>
  );
};

const Container = styled.div<{ img?: string }>`
  position: relative;
  width: 272px;
  height: 320px;
  color: ${theme.WHITE_COLOR};
  background-color: ${theme.GREY_800};
  background-image: ${props => (props.img ? `url(${props.img})` : 'none')};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const MaskDiv = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  bottom: 0;
  width: 100%;
  min-height: 136px;
  padding: 24px;
  padding-top: auto;
  background-color: rgba(0, 0, 0, 0.3);
`;

const TextWrapper = styled.div`
  max-width: 85%;
`;

const IconContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;
  background-color: ${theme.WHITE_COLOR};
`;
