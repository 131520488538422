import { navigate } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import theme from '../../../../util/theme';
import { mobileSize } from '../../../../util/variables';
import { BlogH5, Overline, BlogText } from '../../../ui';

import ReadLink from '../ReadLink';

interface Props {
  category: string;
  title: string;
  description: string;
  imgUrl?: string;
  id: string;
  slug: string;
}

export const ArticleCard: React.FC<Props> = ({
  id,
  category,
  title,
  description,
  imgUrl,
  slug,
}) => {
  return (
    <Container onClick={async () => navigate(`/post/${slug}`)}>
      <Placeholder imgUrl={imgUrl} />
      <Overline>{category}</Overline>
      <BlogH5>{title}</BlogH5>
      <BlogText size="Small">{description}</BlogText>
      <ReadLink link={`/post/${slug}`} />
    </Container>
  );
};

const Container = styled.div`
  width: 352px;
  /* pointer-events: none; */
  cursor: pointer;
  @media ${mobileSize} {
    pointer-events: auto;
  }
`;

const Placeholder = styled.div<{ imgUrl?: string }>`
  width: 352px;
  height: 352px;
  margin-bottom: 16px;
  background-color: ${theme.GREY_800};
  background-image: ${props => (props.imgUrl ? `url(${props.imgUrl})` : 'none')};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* @media ${mobileSize} {
    width: 100%;
    height: auto;
  } */
`;

/* FOR TESTING PURPOSES
<ArticleCard
  category="category"
  title="Q1 through the lens of let's see if we can dovetail these two projects but blue sky thinking feature creep"
  description="Win-win have bandwidth show pony, note for the previous submit: the devil should be on the left shoulder guerrilla marketing."
/>
*/
