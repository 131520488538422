import React, { useState } from 'react';
import styled from 'styled-components';
import theme from '../../../util/theme';
import { laptopSize, mobileSize } from '../../../util/variables';
import { BlogH3, FlexDiv } from '../../ui';
import { ArticleCard } from './cards';

interface Props {
  data: any;
}

export const ArticlesSection: React.FC<Props> = ({ data }) => {
  const [take, setTake] = useState(6);
  return (
    <FlexDiv align="center" direction="column" gap={80}>
      <ArticlesGrid>
        {data.length &&
          data.map(
            (article: any, index: number) =>
              index < take && (
                <ArticleCard
                  key={`article-card-${article.id}`}
                  id={article.contentful_id}
                  category={article.mainCategory?.title}
                  title={article.title}
                  description={article?.summary}
                  imgUrl={article?.image?.file?.url ? `https:${article?.image?.file?.url}` : undefined}
                  slug={article?.slug}
                />
              ),
          )}
        {/* {JSON.stringify(pageContext.urlCategory)} */}
      </ArticlesGrid>
      {data.length > take && (
        <StyledBlogH3 onClick={() => setTake(take => take + 6)}>Load More Articles</StyledBlogH3>
      )}
    </FlexDiv>
  );
};

const StyledBlogH3 = styled(BlogH3)`
  cursor: pointer;
  :hover {
    color: ${theme.PRIMARY_COLOR};
  }
`;

const ArticlesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 80px 40px;
  @media ${laptopSize} {
    grid-template-columns: 1fr 1fr;
  }
  @media ${mobileSize} {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
`;
