import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import IndexLayout from '../layouts';
import {
  BlogH2,
  BlogH5,
  BlogText,
  FlexDiv,
  IconContainer,
  MobileDiv,
  NavBar,
  Overline,
  Separator,
  Wrapper,
} from '../components/ui';
import favicon from '../content/img/icon/favicon.ico';
import theme from '../util/theme';
import { FaTwitter, FaLinkedinIn, FaEnvelope } from 'react-icons/fa';
import { graphql } from 'gatsby';
import { ArticlesSection } from '../components/PageSegments/blog/ArticlesSection';
import { FeaturedArticleCard } from '../components/PageSegments/blog/cards';
// import { renderRichText } from 'gatsby-source-contentful';
import { mobileSize } from '../util/variables';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { BottomNavSegment } from '../components/PageSegments';
import { navigate } from 'gatsby';
import { BackToTop } from '../components/ui/BackToTopButton';
import moment from 'moment';
// import { BLOCKS, MARKS } from '@contentful/rich-text-types';
// import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export default ({ data, pageContext }) => {
  const [currentPost, setCurrentPost] = useState({} as any);

  useEffect(() => {
    if (pageContext?.slug && data?.allContentfulArticles?.nodes[0]?.id) {
      setCurrentPost(data.allContentfulArticles.nodes[0]);
    } else {
      navigate('/blog/');
    }
  }, [data]);

  const currentUrl = `https://base10.vc/post/${pageContext?.slug}`;

  // useEffect(() => {
  //   console.log(currentPost);
  // }, [currentPost]);

  //  const Bold = ({ children }) => <span className="bold">{children}</span>;
  // const Text = ({ children }) => <p className="align-center">{children}</p>;
  // const options: any = {
  //   renderMark: {
  //     [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  //   },
  //   renderNode: {
  //     [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  //     [BLOCKS.EMBEDDED_ASSET]: node => {
  //       return (
  //         <>
  //           <h2>Embedded Asset</h2>
  //           <pre>
  //             <code>{JSON.stringify(node, null, 2)}</code>
  //           </pre>
  //         </>
  //       );
  //     },
  //   },
  // };

  const richTextOptions = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        // console.log('node:', node.data.target.fields.file['en-US']);
        return (
          <img
            src={node.data.target.fields.file['en-US'].url}
            alt={node.data.target.fields.file['en-US'].fileName}
          />
        );
      },
    },
  };

  // const options = {
  //   renderNode: {
  //     [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
  //   },
  // };

  return (
    <IndexLayout>
      <Helmet>
        <title>Base10 Blog - {pageContext?.metadata?.title}</title>
        <link rel="stylesheet" type="text/css" href="/global.css" />
        <link rel="icon" href={favicon} />
        <html lang="en-US" />
        <meta name="theme-color" content="#1a1a1a" />
        <link rel="canonical" href={currentUrl} />
        <meta charSet="UTF-8" />
        <meta name="og:url" content={`https://base10.vc/post/${pageContext?.metadata?.slug}`} />
        <meta name="og:title" content={`Base10 Blog: ${pageContext?.metadata?.title}`} />
        <meta name="og:image" content={pageContext?.metadata?.img} />
        <meta name="image" property="og:image" content={pageContext?.metadata?.img} />
        <meta name="author" content={pageContext?.metadata?.author} />
        {/* <meta name="og:image:width" content="1200" /> */}
        {/* <meta name="og:image:height" content="630" /> */}
        <meta name="og:description" content={pageContext?.metadata?.summary} />
        <meta name="description" content={pageContext?.metadata?.summary} />
        <meta name="twitter:title" content={`Base10 Blog: ${pageContext?.metadata?.title}`} />
        <meta name="twitter:description" content={pageContext?.metadata?.summary} />
        <meta name="twitter:image" content={pageContext?.metadata?.img} />
        <meta name="twitter:image:alt" content={`Base10 Blog: ${pageContext?.metadata?.title}`} />
        <meta name="twitter:site" content="@Base10Partners" />
        <meta name="twitter:creator" content="@Base10Partners" />
        <meta name="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Main>
        <NavBar invert initiallyBlack={false} />
        <HeroGrid>
          <Placeholder imgUrl={`https:${currentPost?.image?.file?.url}`} />
          <HeroContent>
            <Overline />
            <BlogH2>{currentPost.title}</BlogH2>
            <BlogText size="Small">{currentPost.summary}</BlogText>
            <BlogText noMargin size="Small" color={theme.PITCH_BLACK}>
              By <b>{currentPost.author}</b>
            </BlogText>
            <BlogText size="Small" color={theme.GREY_400}>
              {currentPost?.date && moment(currentPost.date).format('MMMM D, YYYY')}
            </BlogText>
            <FlexDiv gap={16}>
              <a
                href={`https://twitter.com/intent/tweet?text=Check%20out%20this%20article%20from%20the%20Base10%20blog!&url=${currentUrl}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconContainer bg="#00A4E9">
                  <FaTwitter color={theme.WHITE_COLOR} size={24} />
                </IconContainer>
              </a>
              <a
                href={`https://www.linkedin.com/sharing/share-offsite/?url=${currentUrl}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconContainer bg="#0E76A8">
                  <FaLinkedinIn color={theme.WHITE_COLOR} size={24} />
                </IconContainer>
              </a>
              <a
                href={`mailto:?subject=Check%20out%20this%20article%20from%20the%20Base10%20blog!&body=Check%20out%20this%20article%20from%20the%20Base10%20blog:%20${currentUrl}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconContainer bg="#173053">
                  <FaEnvelope color={theme.WHITE_COLOR} size={24} />
                </IconContainer>
              </a>
            </FlexDiv>
          </HeroContent>
        </HeroGrid>
        <Wrapper>
          <MobileDiv>
            <Separator />
          </MobileDiv>
          <ContentGrid>
            <StickyWrapper>
              {currentPost?.featured && (
                <Featured>
                  <BlogH5>Featured</BlogH5>
                  {currentPost.featured.map((post: any) => (
                    <FeaturedArticleCard
                      key={`featured-${post.id}`}
                      id={post.contentful_id}
                      category={post.mainCategory.title}
                      title={post.title}
                      imgUrl={post.image.file.url}
                      slug={post.slug}
                    />
                  ))}
                  {/* <FeaturedArticleCard
                  category="CATEGORY"
                  title="Offline this discussion cloud native container based can you send me an invite quick win"
                />
                <FeaturedArticleCard
                  category="CATEGORY"
                  title="Anti-pattern deep dive data-point onward and upward, productize the deliverables"
                /> */}
                </Featured>
              )}
            </StickyWrapper>
            {/* <BlogText size="Small">{renderRichText(currentPost.body)}</BlogText> */}
            {currentPost.body && (
              <BlogTextStyling>
                {documentToReactComponents(JSON.parse(currentPost.body.body), richTextOptions)}
              </BlogTextStyling>
            )}
          </ContentGrid>
          {currentPost.more && (
            <BottomDiv>
              {/* <Separator /> */}
              <ArticlesSection data={currentPost.more} />
            </BottomDiv>
          )}
        </Wrapper>
      </Main>
      <BackToTop />
      <BottomNavSegment />
    </IndexLayout>
  );
};

const BlogTextStyling = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: ${theme.MEDIUM_GREY};
  font-family: ${theme.PRIMARY_FONT};
`;

const BottomDiv = styled.div`
  margin-bottom: 100px;
  @media ${mobileSize} {
    display: none;
  }
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
  /* max-width: min(1164px, 100vw); */
  background-color: ${theme.BACKGROUND_COLOR};
  @media ${mobileSize} {
    padding-top: 80px;
  }
`;

const HeroGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100vw;
  height: calc(100vh - 120px);
  @media ${mobileSize} {
    display: flex;
    flex-direction: column;
    height: auto;
  }
`;

const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 92px;
  @media ${mobileSize} {
    padding: 24px;
  }
`;

const Placeholder = styled.div<{ imgUrl: string }>`
  height: 100%;
  width: 100%;
  background-image: ${props => (props.imgUrl ? `url(${props.imgUrl})` : theme.GREY_800)};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media ${mobileSize} {
    width: 100vw;
    height: auto;
    aspect-ratio: 1/1;
  }
`;

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 128px;
  max-width: min(1164px, 100vw);
  padding: 80px 0;
  @media ${mobileSize} {
    grid-gap: 64px;
    display: flex;
    flex-direction: column-reverse;
    max-width: 100vw;
    padding: 24px;
    padding-bottom: 60px;
  }
`;

const StickyWrapper = styled.div`
  position: relative;
  height: 100%;
`;

const Featured = styled.div`
  display: Flex;
  flex-direction: column;
  grid-gap: 40px;
  position: sticky;
  top: 0;
`;

export const query = graphql`
  query ContentfulArticle($slug: String) {
    allContentfulArticles(filter: { slug: { eq: $slug } }) {
      totalCount
      nodes {
        id
        contentful_id
        slug
        title
        summary
        mainCategory {
          id
          title
        }
        author
        date
        image {
          id
          file {
            url
          }
        }
        previewImage {
          id
          file {
            url
          }
        }
        updatedAt
        body {
          body
        }
        featured {
          id
          contentful_id
          slug
          title
          image {
            id
            file {
              url
            }
          }
          previewImage {
            id
            file {
              url
            }
          }
          mainCategory {
            id
            title
          }
        }
        more {
          id
          contentful_id
          slug
          title
          summary
          image {
            id
            file {
              url
            }
          }
          previewImage {
            id
            file {
              url
            }
          }
          mainCategory {
            id
            title
          }
        }
      }
    }
  }
`;
