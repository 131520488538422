import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import theme from '../../../util/theme';
import { mobileSize } from '../../../util/variables';

interface Props {
  link: string;
  fontSize?: number;
}

const ReadLink: React.FC<Props> = ({ link, fontSize }) => {
  return <StyledLink to={link} fontSize={fontSize}>Read Article</StyledLink>;
};

const StyledLink = styled(Link) <{ fontSize: number | undefined }>`
  width: max-content;
  border-bottom: 3px solid ${theme.PRIMARY_COLOR};
  font-family: ${theme.PRIMARY_FONT};
  font-size: ${props => props.fontSize ? props.fontSize : 20}px;
  font-weight: 700;
  line-height: 25px;
  color: ${theme.BLACK_COLOR};
  @media ${mobileSize} {
    display: none;
  }
  pointer-events: auto;
`;

export default ReadLink;
