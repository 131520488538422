import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from '../../util/theme';
import Arrow from '../../content/img/icon/ToTopArrow.svg';

export const BackToTop = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const scrollHandler = () =>
      window.scrollY >= window.innerHeight / 2 ? setShowButton(true) : setShowButton(false);
    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <BTTContainer show={showButton} onClick={scrollToTop}>
      <img src={Arrow} alt="Up Arrow with bar at the top" />
    </BTTContainer>
  );
};

const BTTContainer = styled.div<{ show: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 32px;
  bottom: ${props => props.show ? 40 : -50}px;

  width: 48px;
  height: 48px;

  margin-top: 1rem;
  border-radius: 50%;

  background-color: ${theme.PRIMARY_COLOR};

  cursor: pointer;
  transition: bottom 0.4s ease-in-out;
`;
