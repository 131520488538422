import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import theme from '../../../../util/theme';
import { mobileSize } from '../../../../util/variables';
import { BlogH5, Overline } from '../../../ui';

interface Props {
  category: string;
  title: string;
  imgUrl: string;
  id: string;
  slug: string;
}

export const FeaturedArticleCard: React.FC<Props> = ({ id, category, title, imgUrl, slug }) => {
  return (
    <Container to={`/post/${slug}`}>
      {/* <img src={imgUrl} alt={'text'} /> */}
      <ImageContainer imgUrl={imgUrl} />
      <div>
        <Overline>{category}</Overline>
        <BlogH5>{title}</BlogH5>
      </div>
    </Container>
  );
};

const Container = styled(Link)`
  display: flex;
  align-items: center;
  gap: 40px;
  max-width: 528px;
  color: ${theme.PITCH_BLACK};
`;

const ImageContainer = styled.div<{ imgUrl: string }>`
  width: 160px;
  height: 160px;
  aspect-ratio: 1/1;
  background-color: ${theme.GREY_800};
  background-image: ${props => (props.imgUrl ? `url(${props.imgUrl})` : theme.GREY_800)};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media ${mobileSize} {
    width: 100px;
    height: 100px;
  }
`;

/* FOR TESTING PURPOSES
<FeaturedArticleCard
  category="category"
  title="Q1 through the lens of let's see if we can dovetail these two projects but blue sky thinking feature creep"
/>
*/
