import { navigate } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { mobileSize } from '../../../../util/variables';
import { Overline, BlogText, BlogH2, FlexDiv } from '../../../ui';

import ReadLink from '../ReadLink';

interface Props {
  size: 'hero' | 'large';
  category: string;
  title: string;
  description: string;
  imgUrl: any;
  id: string;
  slug: string;
}

export const MainFeaturedArticleCard: React.FC<Props> = ({
  id,
  size,
  category,
  title,
  description,
  imgUrl,
  slug,
}) => {
  return (
    <Container gap={size === 'hero' ? 112 : 80} onClick={async () => navigate(`/post/${slug}`)}>
      {/* <img src={ } alt={ } /> */}
      <ImageContainer size={size} src={imgUrl ?? undefined} />
      <div>
        <Overline>{category}</Overline>
        <BlogH2>{title}</BlogH2>
        {size === 'hero' && <BlogText size="Small">{description}</BlogText>}
        <ReadLink link={`/post/${slug}`} fontSize={size === 'hero' ? 24 : 20} />
      </div>
    </Container>
  );
};

const Container = styled(FlexDiv as any)`
  align-items: center;
`;

interface SizeProp {
  size: 'hero' | 'large';
}

const ImageContainer = styled.img<SizeProp>`
  width: ${props => (props.size === 'hero' ? 560 : 352)}px;
  height: ${props => (props.size === 'hero' ? 560 : 352)}px;
  aspect-ratio: 1/1;
  /* background-color: ${theme.GREY_800}; */
  @media ${mobileSize} {
    width: 100px;
    height: 100px;
  }
`;
